// TODO: Implement it properly
// use classNames instead
import React from 'react';
import styled from 'styled-components';
import { Link as RenditionLink } from 'rendition';
import { palette } from '../utils/colors';

/*
  A link can

  - be highlighted with ${palette.sanJuan} // (is-hightlighted)
  - have underline effect // (has-hover-underline)
  - have background hover effect // has-hover-background

*/

const ExternalLink = styled(RenditionLink)`
  position: relative;
  font-weight: ${props => props.fontWeight || 'inherit'};
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : 'inherit')};
  color: ${props => props.color || palette.sanJuan};
  margin: ${props => props.margin || 0};
  line-height: 1.4;

  &:before {
    content: ' ';
    position: absolute;
    width: 100%;
    left: 0;
    bottom: ${props => props.underlinePosition || '-4'}px;
    border: ${props =>
      props.highlight
        ? '1px solid ' + (props.color || palette.frenchPass)
        : 'transparent'};
    transition: border-color 0.1s ease-in;
  }

  &:hover {
    &:before {
      border: ${props =>
        props.highlight || props.underline
          ? '1px solid ' +
            (props.highlightColor || props.color || palette.sanJuan)
          : 'transparent'};
    }
  }
`;

// const InternalLink = styled(Link)`
//   position: relative;
//   font-weight: ${props => props.fontWeight || 'inherit'};
//   font-size: ${props => (props.fontSize ? `${props.fontSize}px` : 'inherit')};
//   color: ${props => props.color || palette.sanJuan};
//   margin: ${props => props.margin || 0};
//   line-height: 1.4;
//   display: inline-block;

//   &:before {
//     content: ' ';
//     position: absolute;
//     width: 100%;
//     left: 0;
//     bottom: ${props => props.underlinePosition || '-4'}px;
//     border: ${props =>
//       props.highlight
//         ? '1px solid ' + (props.color || palette.frenchPass)
//         : 'transparent'};
//     transition: border-color 0.1s ease-in;
//   }

//   &:hover {
//     &:before {
//       border: ${props =>
//         props.highlight || props.underline
//           ? '1px solid ' +
//             (props.highlightColor || props.color || palette.sanJuan)
//           : 'transparent'};
//     }
//   }
// `;

const SiteLink = ({
  children,
  ext,
  href,
  label,
  text,
  ...rest
}) => {
  // if (ext) {
    return (
      <ExternalLink
        href
        aria-label={label}
        blank
        {...rest}
      >
        {children || text}
      </ExternalLink>
    );
  // }

  // return (
  //   <InternalLink to={href} aria-label={label} {...rest}>
  //     {children || text}
  //   </InternalLink>
  // );
};

export default SiteLink;
