import React from 'react';
// import BaseIcon from '../../components/Icon';
import styled from 'styled-components';

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  margin: 0;
  height: 100%;

  .legacy-background-color {
    background-color: #535760;
  }
  .new-background-color {
    background-color: #4d5057;
  }
  .horizontal {
    display: flex;
  }
  .vertical {
    display: flex;
    flex-direction: column;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ml-2 {
    margin-left: 61px;
  }
  .button {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 35px;
    background-color: #c9cacd;
    color: #4c5059;
    border-radius: 2px;
    text-decoration: none;
    text-align: center;

    &.outdated-button {
      padding: 0 20px;
      background-color: #fca321;
      color: #fff;
      width: 160px;
      height: 45px;
    }
  }
  .button > img {
    margin: 0 0 0 10px;
  }
`;

export const Main = styled.main`
  position: relative;
  height: 100vh;
  padding: 0 48px;
`;

export const Aligner = styled.div`
  height: 100%;
  width: 100%;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-top: 1px dashed #979797;
  box-sizing: border-box;
`;

export const LinkButton = ({ className, href, children, ...props }) => (
  <a className={`button ${className || ''}`} href={href || ''} {...props}>
    {children}
  </a>
);

// export const Icon = styled(BaseIcon)`
//   &&& {
//     color: #fff !important;
//   }
// `;
