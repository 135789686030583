import React from "react";
import ReactDOM from "react-dom/client";
// import EFP from "./EFP";
// import { EtcherPro } from "./EtcherPro";
import Success from "./success";
import OpenImageURL from "./open-image-url";
import Layout from "./components/Layout";
import { Provider } from "rendition";
import theme from './utils/theme';
import success from './data/success.json';
import efp from './data/efp.json';

const root = ReactDOM.createRoot(document.getElementById("root"));

const NaiveRouter = ({ url }) => {
  if (url.includes("success-banner")) return <Success data={success} />;
  if (url.includes("open-image-url")) return <OpenImageURL />;
  // return <EFP />; // commenting out while we try EtcherPro
  return <Success data={efp} />;
};

root.render(
  <React.StrictMode>
    <Layout>
      <Provider theme={{ font: theme.font }}>
        <NaiveRouter url={window.location.href} />
      </Provider>
    </Layout>
  </React.StrictMode>
);
