import { Flex } from 'rendition';
import styled from 'styled-components';

import Link from './Link';

import { invertBreakpoint } from '../utils/helpers';
import { palette } from '../utils/colors';

export const GithubLink = styled(Link)`
  position: absolute;
  top: 0;
  right: 16px;
`;

// The next section (Hero of each page, should offset 80px,
// so that the header will blend in without overriding any text
export const Wrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  background: transparent;
  z-index: 5;

  + div:first-of-type {
    margin-top: 80px;
  }

  .Header__mobile-logo,
  .Header__hamburger {
    display: none;
  }

  .Header__login-signup-mobile {
    display: none;

    min-height: 84px;

    a {
      font-size: 14px !important;
    }
  }

  .Header__navigation-item__description {
    white-space: nowrap;
  }

  @media (max-width: ${props =>
      invertBreakpoint(props.theme.breakpoints[2])}px) {
    position: sticky;
    top: 0;
    background: white;
    box-shadow: 0px 0px 21px 5px rgba(152, 173, 227, 0.2);

    .Header__main-logo,
    .Header__main-logo-lead,
    .Header__github-link,
    .Header__login-signup {
      display: none;
    }

    .Header__login-signup-mobile {
      display: flex;
    }

    .Header__hamburger {
      display: block;
    }

    .Header__navigation-mask {
      position: fixed;
    }

    .Header__navigation-items {
      position: fixed;
      background: white;
      top: 0;
      right: 0;
      width: 300px;
      bottom: 0;
      justify-content: flex-start;
      transform: translateX(100%);
      transition: transform 200ms ease-in-out;
      padding: 16px;
      overflow: auto;
      flex-wrap: nowrap;
    }

    .Header__navigation-item {
      margin: 0;

      a {
        margin: 0 !important;
        color: ${palette.sanJuan};
        display: block !important;
        padding-top: 12px !important;
        padding-bottom: 10px !important;
        font-size: initial;

        + div .Header__navigation-item {
          padding-left: 10px !important;
        }

        &:hover:before {
          border: none;
        }
      }

      svg {
        float: right;
        transform: translateY(4px);

        path {
          fill: ${palette.cerulian};
        }
      }
    }

    .Header__navigation-item__description {
      white-space: normal;
    }

    .Header__navigation-items--menu-open {
      position: fixed;
      transform: translateX(0%);
    }

    .Header__mobile-logo {
      display: block;
      height: 30px;
    }
  }
`;

export const Navbar = styled(Flex)`
  min-height: 80px;
  position: relative;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${props =>
      invertBreakpoint(props.theme.breakpoints[2])}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: auto;
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;
