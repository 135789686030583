/* eslint-disable react/jsx-pascal-case */

import React from 'react';
import { Box } from 'rendition/dist_esm5/components/Box';
import { Flex } from 'rendition/dist_esm5/components/Flex';
import { Img } from 'rendition/dist_esm5/components/Img';
import { Txt } from 'rendition/dist_esm5/components/Txt';
import { Button } from 'rendition/dist_esm5/components/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTriangleExclamation, faSyncAlt } from "@fortawesome/free-solid-svg-icons"
import {
  Aligner,
  // Icon,
  LinkButton,
  Main,
  StyledDiv,
} from '../sections/etcher/SuccessBannerUtils';
import styled from 'styled-components';
import { Markdown } from 'rendition/dist/extra/Markdown';

function getPreviewCompatibleImage(image) {
  if (!!image && !!image.childImageSharp) {
    return image.childImageSharp.fluid.src;
  }
  if (!!image && typeof image === 'string') return image;

  return null;
}

const HorizontalWrapper = styled.div`
  width: 780px;
  height: 300px;
  overflow: hidden;
`;
const VerticalWrapper = styled.div`
  width: 490px;
  height: 460px;
  overflow: hidden;
`;

// Fallback for outdated versions
export const OutdatedVersionBanner = props => (
  <StyledDiv>
    <Main className={props.className}>
      <Aligner>
        <div style={{ flexDirection: 'column', alignItems: 'stretch' }}>
          <div className="vertical center">
            <h2 style={{ marginBottom: 30, fontSize: 28 }}>
              <Txt.span style={{ marginRight: 10 }} color="grey">
                <FontAwesomeIcon icon={faTriangleExclamation} />
              </Txt.span>
              {/* <Icon
                icon={['fa', 'exclamation-triangle']}
                size="xs"
                style={{ marginRight: 10, color: 'grey' }}
              /> */}
              Your Etcher is <b>out of date</b>, please update.
            </h2>
            <LinkButton
              href="https://balena.io/etcher/"
              target="blank"
              className="outdated-button"
            >
              <Txt.span style={{ marginRight: 8.5, fontSize: 14 }} color="#fff">
                <FontAwesomeIcon icon={faSyncAlt} />
              </Txt.span>
              {/* <Icon
                icon={['fa', 'sync-alt']}
                style={{ marginRight: 8.5, fontSize: 14 }}
              /> */}
              
              Update balenaEtcher
            </LinkButton>
          </div>
        </div>
      </Aligner>
    </Main>
  </StyledDiv>
);

const Title = ({ text }) => (
  <Markdown
    sanitizerOptions={null}
    disableCodeHighlight
    componentOverrides={{
      p: props => (
        <Txt
          {...props}
          ml={2}
          className="circuclar"
          fontSize={22}
          style={{ textAlign: 'center' }}
        />
      ),
    }}
  >
    {text}
  </Markdown>
);
const Lead = ({ text, vertical }) => (
  <Markdown
    sanitizerOptions={null}
    disableCodeHighlight
    mt={3}
    componentOverrides={{
      p: props => (
        <Txt
          {...props}
          color="#ffffff"
          fontSize={vertical ? 16 : 15}
          align="center"
          mb={vertical ? 1 : 12}
        />
      ),
    }}
  >
    {text}
  </Markdown>
);
const Text = ({ text, vertical }) => (
  <Txt
    color={vertical ? '#c6c8c9' : '#c9cacd'}
    fontSize={vertical ? 12 : 14}
    align="center"
    mb={24}
    style={{ maxWidth: vertical ? 'none' : 251, lineHeight: '14px' }}
  >
    {text}
  </Txt>
);

export const VerticalView = ({ text, image, cta, redirect, ...props }) => (
  <Flex
    style={{
      backgroundColor: '#414449',
    }}
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    minWidth="100%"
    height="100vh"
    mx="auto"
  >
    <VerticalWrapper>
      <StyledDiv style={{ backgroundColor: '#414449' }}>
        <Aligner
          style={{
            borderTop: 'none',
          }}
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Img
              src={getPreviewCompatibleImage(image)}
              width={340}
              height={143}
            />
            <Lead vertical text={props.lead} />
            <Text vertical text={text} />
            <Button
              mt={8}
              href={redirect}
              target="_blank"
              style={{ color: 'white', borderColor: 'white' }}
            >
              {cta}
            </Button>
          </Flex>
        </Aligner>
      </StyledDiv>
    </VerticalWrapper>
  </Flex>
);

export const HorizontalView = ({ darkBackground, borderTop, activeBanner }) => {
  return (
    <Flex
      style={{
        backgroundColor: darkBackground === 'true' ? '#414449' : '#4d5057',
      }}
      flexDirection="column"
      justifyContent={borderTop === 'true' ? 'flex-start' : 'center'}
      alignItems="center"
      minWidth="100%"
      height="100vh"
      mx="auto"
    >
      <HorizontalWrapper>
        <StyledDiv
          style={{
            backgroundColor: darkBackground === 'true' ? '#414449' : '#4d5057',
          }}
        >
          <Aligner
            style={{
              borderTop: borderTop === 'true' ? '1px dashed #979797' : 'none',
            }}
          >
            <Flex justifyContent="space-between" alignItems="center">
              {/* prevents jumping around while image loads */}
              <Img
                src={getPreviewCompatibleImage(activeBanner.image)}
                style={{
                  margin: '0 auto',
                  maxWidth: '308px',
                  maxHeight: '200px',
                }}
              />

              <Box ml={4} className="vertical center">
                {activeBanner.title && (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    mb={10}
                    width="100%"
                  >
                    {activeBanner.logo && (
                      <Img
                        height="33px"
                        src={getPreviewCompatibleImage(activeBanner.logo)}
                        retina={false}
                      />
                    )}
                    <Title text={activeBanner.title} />
                  </Flex>
                )}
                <Flex flexDirection="column" alignItems="center">
                  {activeBanner.lead && <Lead text={activeBanner.lead} />}
                  {activeBanner.text && <Text text={activeBanner.text} />}
                  <Button
                    meta={{ vn: 'b' }}
                    href={`${activeBanner.redirect}${activeBanner.redirect.includes('?') ? '' : '?'}&utm_medium=vnb`}
                    target="_blank"
                    outline={true}
                    color="#fff"
                    style={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: '#fff',
                      borderColor: '#fff',
                    }}
                  >
                    {activeBanner.cta.icon && (
                      <Txt.span fontSize={1} color="#fff">
                        <FontAwesomeIcon icon={activeBanner.cta.icon.split('-')} />
                      </Txt.span>
                      // <Icon
                      //   size="1x"
                      //   icon={}
                      //   style={{ marginRight: '8px' }}
                      // />
                    )}
                    {activeBanner.cta.label}
                  </Button>
                </Flex>
              </Box>
            </Flex>
          </Aligner>
        </StyledDiv>
      </HorizontalWrapper>
    </Flex>
  );
};
