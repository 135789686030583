/* eslint-disable react/jsx-pascal-case */

import React, { PureComponent } from "react"
import { Provider } from "rendition"
import { createGlobalStyle } from "styled-components"
import { reset } from "styled-reset"

import { baseStyles, helperClasses, sliderStyles } from "../utils/styles"
import theme from "../utils/theme"

export const GlobalStyle = createGlobalStyle`
  ${reset}
  ${baseStyles}
  ${helperClasses}
  ${sliderStyles}
`

class Layout extends PureComponent {
  render() {
    const { children } = this.props

    return (
      <Provider theme={theme}>
        <GlobalStyle />
        <>{children}</>
      </Provider>
    )
  }
}

export default Layout
