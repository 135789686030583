import { palette } from './colors';

export const baseStyles = `
html {
  font-size: 10px;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  color: ${palette.sanJuan};
  line-height: 1.4;
}

a {
  text-decoration: none;
  color: ${palette.cerulian};
}

b, strong {
  font-weight: bold;
}

h1, h2, h2, h3, h4, h5, h6 {
  font-family: 'Source Sans Pro';
  font-weight: 600;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.gatsby-highlight {
  border-radius: 0.3em;
  margin: 0.5em 0;
  padding: 1em;
  overflow: auto;
}

.gatsby-highlight pre[class*="language-"] {
  background-color: transparent;
  margin: 0;
  padding: 0;
  overflow: initial;
  float: left; /* 1 */
  min-width: 100%; /* 2 */
}

.gatsby-highlight code[class*="language-"] {
  border: 0;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 1px #dfdfdf;
}

code[class*="language-"] {
  font-size: 14px!important;
}

`;

export const helperClasses = `
  .text-center {
    text-align: center;
  }
  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }
  .relative {
    position: relative;
  }
`;

export const sliderStyles = `
.input-range__slider {
  appearance: none;
  background: ${palette.cerulian};
  border: 1px solid ${palette.cerulian};
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1.6rem;
  margin-left: -0.5rem;
  margin-top: -1.2rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  background: #fff;
  border: 1px solid #527699;
  width: 1.6rem;
}

.input-range__slider:before {
  content: ' ';
  position: absolute;
  left: 4px;
  top: 4px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #00aeef;
}

  .input-range__slider:active {
    transform: scale(1.1); }
  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2); }
  .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    transform: none; }

.input-range__slider-container {
  transition: left 0.3s ease-out; }

.input-range__label {
  color: #aaaaaa;
  font-family: "Helvetica Neue", san-serif;
  font-size: 0.8rem;
  transform: translateZ(0);
  white-space: nowrap; }

.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute; }

.input-range__label--min {
  left: 0; }

.input-range__label--max {
  right: 0; }

.input-range__label--value {
  position: absolute;
  top: -1.8rem; }

.input-range__label-container {
  left: -50%;
  position: relative; }
  .input-range__label--max .input-range__label-container {
    left: 50%; }

.input-range__track {
  background: ${palette.blueHaze};
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.6rem;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out; }
  .input-range--disabled .input-range__track {
    background: ${palette.blueHaze} }

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%; }

.input-range__track--active {
  background: ${palette.cerulian}; }

.input-range {
  height: 1rem;
  position: relative;
  width: 100%; }

`;
