import * as _ from 'lodash';
import React, { useEffect } from 'react';
import { Box, Container, Flex, Link, Provider, Txt } from 'rendition';
import { createGlobalStyle, default as styled } from 'styled-components';
import { reset } from 'styled-reset';

import Header from './components/Header';
import SmallPrintFooterBase from './components/SmallPrintFooter';
import balenaEtcherLogo from './images/landing-pages/etcher/balena-etcher.svg';
import { palette } from './utils/colors';
import { baseStyles, helperClasses, sliderStyles } from './utils/styles';

const background = palette.sanJuan;
const SmallPrintFooter = styled(SmallPrintFooterBase)`
  position: absolute;
  bottom: 0;
`;

const GlobalStyle = createGlobalStyle`
  ${reset}
  ${baseStyles}
  ${helperClasses}
  ${sliderStyles}

  body {
    background-color: ${background};
    color: #ffffff;
    text-align: center;
  }
`;

const logoProps = {
  image: balenaEtcherLogo,
  lead: 'An open source project by',
  redirectTo: 'https://www.balena.io/etcher',
  altText: 'balenaEtcher',
};

const CenteredContainer = styled(Container)`
  max-width: 100vw;
  width: 100vw;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const getUrlParam = (query, param) =>
  _.get(
    _.split(_.tail(_.split(query, '?')), '&').reduce((acc, item) => {
      const [key, val] = item.split('=');
      acc[key] = val;
      return acc;
    }, {}),
    param
  );

const OpenImageURL = props => {
  useEffect(
    () => {
      // We don't use new URL(...) because it transforms parameters
      const path = getUrlParam(window.location, 'imageUrl');
      const etcherUrl = `etcher://${decodeURIComponent(path)}`;
      window.location.href = etcherUrl;
    },
    []
  );
  return (
    <Provider>
      <Header
        logoProps={logoProps}
        routes={[]}
        location={"https://www.balena.io/etcher"}
        logoOnly={true}
      />
      <Flex height="100vh" flexDirection="column" color="#fff">
        <GlobalStyle />
        <CenteredContainer textAlign="center">
          <Container height="inherit">
            <Txt fontSize={34}>Opening etcher…</Txt>
            <Txt fontSize={16}>
              Please click "Open balenaEtcher" if you see the dialogue above.
            </Txt>
            <Box my={6} />
            <Txt fontSize={18}>
              If you haven't installed it already,{' '}
              <Link href={'https:///www.balena.io/etcher'} color="#a5de37">
                download balenaEtcher
              </Link>{' '}
              now.
            </Txt>
          </Container>
        </CenteredContainer>
        <SmallPrintFooter
          width="100vw"
          theme={{
            bg: background,
            color: '#ffffff',
            fontSize: 14,
            opacity: 0.8,
          }}
        />
      </Flex>
    </Provider>
  );
};

export default OpenImageURL;
