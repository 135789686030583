import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Box, Flex, Txt } from 'rendition';
import Link from './Link';
import { palette } from '../utils/colors';

const links = [
  {
    path: 'https://www.balena.io/terms-of-service',
    title: 'Terms of Service',
  },
  {
    path: 'https://www.balena.io/privacy-policy',
    title: 'Privacy Statement',
  },
  {
    path: 'https://www.balena.io/master-agreement',
    title: 'Master agreement',
  },
];

const Divider = styled(Box).attrs({ mx: 2 })`
  border-left: 1px solid;
  height: 11px;
`;

const SmallPrintFooter = ({ theme, ...props }) => {
  let bg, color, opacity, fontSize;
  if (typeof theme === 'object') {
    ({ bg, color, opacity, fontSize } = theme);
  }
  return (
    <Flex
      bg={bg || (theme === 'dark' ? '#fff' : palette.whiteLilac)}
      justifyContent="center"
      alignItems="center"
      p={20}
      color={color || palette.kashmirBlue}
      flexWrap="wrap"
      {...props}
    >
      {links.map((link, idx) => (
        <Fragment key={idx}>
          <Link
            fontSize={fontSize || 13}
            color={color || palette.kashmirBlue}
            href={link.path}
            style={{ opacity }}
          >
            {link.title}
          </Link>
          <Divider />
        </Fragment>
      ))}

      <Txt
        fontSize={fontSize || 13}
        color={color || palette.kashmirBlue}
        style={{ opacity }}
      >
        Copyright {new Date().getFullYear()} Balena
      </Txt>
      <Divider />
      <Txt
        fontSize={fontSize || 13}
        color={color || palette.kashmirBlue}
        style={{ opacity }}
      >
        All Rights Reserved
      </Txt>
    </Flex>
  );
};

export default SmallPrintFooter;
